import 'core-js/es6/symbol';
import 'core-js/es6/string';
import 'core-js/es6/array';
import 'core-js/es7/array';
import 'core-js/es7/object';
import 'core-js/es6/map';
import 'core-js/es6/set';
import 'core-js/es6/promise';
import 'scrolling-element';

if (!window.location.origin) {
  window.location.origin = window.location.protocol + '//' + window.location.host;
}

// missing forEach on NodeList for IE11
if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}
